<template>
  <div id="NewView">
    <!-- <div id="newView-title">{{ notice.title }}</div> -->
    <div id="newView-content" ref="newView-content">{{ notice.content }}</div>
  </div>
</template>

<script>
export default {
  name: "NewView",
  beforeCreate() {
    // console.log(
    //   this.$route.path,
    //   "\n" + new Date().valueOf() + " beforeCreate: \n"
    // );
    this.getNotices = () => {
      // 获取公告列表
      this.$axios
        .get("notice", { params: { id: this.$route.params.id } })
        .then((res) => {
          if (res.code === 0) {
            const a = this.$(res.data.content);
            this.$("#newView-content").append(a);
          } else {
            console.log(res.code, res.message);
            this.$message.warning(res.message);
          }
        });
    };
    this.getNotices();
  },
  // 数据
  data() {
    return {
      notice: { title: "", content: "" },
    };
  },
  created() {
    // （this）data、methods 已初始化，数据（data）、方法(methods) 尚未 编译成模版，只是存在内存中
    console.log(this.$route.path, "\n" + new Date().valueOf() + " created: \n");
  },
};
</script>

<!-- 添加"scoped"属性，以限制CSS仅对该组件 -->
<style lang="less">
#NewView {
  #newView-title {
    text-align: center;
    //: 1.3em;
  }

  #newView-content {
    max-width: 100%;
    // background-color: initial !important;

    * {
      max-width: 100%;
      background-color: initial !important;
    }
    p,
    span {
      background-color: initial !important;
    }

    video {
      height: auto !important;
    }
  }
}
</style>
